import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ma-2 pa-2" },
    [
      _c(
        VCard,
        { staticClass: "pa-3", attrs: { height: "90vh" } },
        [
          _c(
            VTabs,
            { staticClass: "grey lighten-3" },
            [
              _c(
                VTab,
                { staticClass: "grey lighten-3" },
                [
                  _c(VIcon, { attrs: { left: "" } }, [
                    _vm._v(" mdi-account ")
                  ]),
                  _vm._v(" Meus dados ")
                ],
                1
              ),
              _c(
                VTab,
                { staticClass: "grey lighten-3" },
                [
                  _c(VIcon, { attrs: { left: "" } }, [_vm._v(" mdi-lock ")]),
                  _vm._v(" Segurança ")
                ],
                1
              ),
              _c(
                VTabItem,
                [
                  _c(
                    VCard,
                    { staticClass: "ma-3 pa-3", attrs: { flat: "" } },
                    [
                      _c(
                        VCardText,
                        {},
                        [
                          _c(VCardText, { staticClass: "mb-3" }, [
                            _vm._v(" Informações básicas do sistema ")
                          ]),
                          _c(
                            VRow,
                            [
                              _c(
                                VCol,
                                {
                                  attrs: {
                                    cols: "12",
                                    sm: "12",
                                    md: "6",
                                    lg: "6"
                                  }
                                },
                                [
                                  _c(
                                    VRow,
                                    [
                                      _c(
                                        VCol,
                                        {
                                          attrs: {
                                            cols: "6",
                                            sm: "12",
                                            md: "5",
                                            lg: "5"
                                          }
                                        },
                                        [
                                          _c(
                                            VCardText,
                                            [
                                              _c(
                                                VIcon,
                                                { staticClass: "mx-1" },
                                                [
                                                  _vm._v(
                                                    "mdi-information-outline"
                                                  )
                                                ]
                                              ),
                                              _vm._v("Nome de usuário:")
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        VCol,
                                        {
                                          attrs: {
                                            cols: "6",
                                            sm: "12",
                                            md: "5",
                                            lg: "5"
                                          }
                                        },
                                        [
                                          _c(VTextField, {
                                            attrs: {
                                              solo: "",
                                              small: "",
                                              readonly: "",
                                              disabled: ""
                                            },
                                            model: {
                                              value: _vm.computedGetUserName,
                                              callback: function($$v) {
                                                _vm.computedGetUserName = $$v
                                              },
                                              expression: "computedGetUserName"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    VRow,
                                    [
                                      _c(
                                        VCol,
                                        {
                                          attrs: {
                                            cols: "6",
                                            sm: "12",
                                            md: "5",
                                            lg: "5"
                                          }
                                        },
                                        [
                                          _c(
                                            VCardText,
                                            [
                                              _c(
                                                VIcon,
                                                { staticClass: "mx-1" },
                                                [
                                                  _vm._v(
                                                    "mdi-information-outline"
                                                  )
                                                ]
                                              ),
                                              _vm._v("Login:")
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        VCol,
                                        {
                                          attrs: {
                                            cols: "6",
                                            sm: "12",
                                            md: "5",
                                            lg: "5"
                                          }
                                        },
                                        [
                                          _c(VTextField, {
                                            attrs: {
                                              solo: "",
                                              small: "",
                                              readonly: "",
                                              disabled: ""
                                            },
                                            model: {
                                              value: _vm.computedGetUserLogin,
                                              callback: function($$v) {
                                                _vm.computedGetUserLogin = $$v
                                              },
                                              expression: "computedGetUserLogin"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    VRow,
                                    [
                                      _c(
                                        VCol,
                                        {
                                          attrs: { sm: "12", md: "5", lg: "5" }
                                        },
                                        [
                                          _c(
                                            VCardText,
                                            [
                                              _c(
                                                VIcon,
                                                { staticClass: "mx-1" },
                                                [
                                                  _vm._v(
                                                    "mdi-information-outline"
                                                  )
                                                ]
                                              ),
                                              _vm._v("E-mail do usuário:")
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        VCol,
                                        {
                                          attrs: { sm: "12", md: "5", lg: "5" }
                                        },
                                        [
                                          _c(VTextField, {
                                            attrs: {
                                              solo: "",
                                              small: "",
                                              readonly: "",
                                              disabled: ""
                                            },
                                            model: {
                                              value: _vm.computedGetUserEmail,
                                              callback: function($$v) {
                                                _vm.computedGetUserEmail = $$v
                                              },
                                              expression: "computedGetUserEmail"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VTabItem,
                [
                  _c(
                    VCard,
                    { staticClass: "ma-3 pa-3", attrs: { flat: "" } },
                    [
                      _c(
                        VCardText,
                        [
                          _c(VCardText, { staticClass: "mb-3" }, [
                            _vm._v(
                              " Deseja redefinir sua senha? Utilize o campo abaixo para criar uma nova senha: "
                            )
                          ]),
                          _c(
                            VForm,
                            {
                              ref: "form",
                              nativeOn: {
                                keyup: function($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  ) {
                                    return null
                                  }
                                  return _vm.submit.apply(null, arguments)
                                }
                              },
                              model: {
                                value: _vm.valid,
                                callback: function($$v) {
                                  _vm.valid = $$v
                                },
                                expression: "valid"
                              }
                            },
                            [
                              _c(
                                VRow,
                                [
                                  _c(
                                    VCol,
                                    { attrs: { sm: "12", lg: "3", xl: "3" } },
                                    [
                                      _c(
                                        VCardText,
                                        [
                                          _c(VIcon, [_vm._v("mdi-lock")]),
                                          _vm._v(" Nova senha:")
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { sm: "12", lg: "4", xl: "4" } },
                                    [
                                      _c(VTextField, {
                                        attrs: {
                                          solo: "",
                                          loading: "",
                                          autocomplete: "current-password",
                                          value: _vm.userPassword,
                                          label: "Crie uma senha",
                                          hint:
                                            "Sua senha precisa conter no mín. 12 caracteres: maiúsculas, números e caracteres especiais (exemplo: @,!,#)",
                                          "append-icon": _vm.show1
                                            ? "mdi-eye"
                                            : "mdi-eye-off",
                                          type: _vm.show1 ? "text" : "password",
                                          rules: _vm.passwordRules
                                        },
                                        on: {
                                          "click:append": function($event) {
                                            _vm.show1 = !_vm.show1
                                          }
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "progress",
                                            fn: function() {
                                              return [
                                                _vm.custom
                                                  ? _c(VProgressLinear, {
                                                      attrs: {
                                                        value: _vm.progress,
                                                        absolute: "",
                                                        height: "7"
                                                      }
                                                    })
                                                  : _vm._e()
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ]),
                                        model: {
                                          value: _vm.password,
                                          callback: function($$v) {
                                            _vm.password = $$v
                                          },
                                          expression: "password"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                VRow,
                                [
                                  _c(
                                    VCol,
                                    { attrs: { sm: "12", lg: "3", xl: "3" } },
                                    [
                                      _c(
                                        VCardText,
                                        [
                                          _c(VIcon, [_vm._v("mdi-lock")]),
                                          _vm._v(" Repetir senha:")
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { attrs: { sm: "12", lg: "4", xl: "4" } },
                                    [
                                      _c(VTextField, {
                                        attrs: {
                                          solo: "",
                                          name: "Repita a nova senha",
                                          small: "",
                                          label: "Repita a nova senha",
                                          type: _vm.show1 ? "text" : "password",
                                          "append-icon": _vm.show1
                                            ? "mdi-eye"
                                            : "mdi-eye-off",
                                          "data-cy": "signinPasswordField",
                                          rules: _vm.passwordRulesConfirmation,
                                          required: ""
                                        },
                                        on: {
                                          "click:append": function($event) {
                                            _vm.show1 = !_vm.show1
                                          }
                                        },
                                        model: {
                                          value: _vm.passwordConfirmation,
                                          callback: function($$v) {
                                            _vm.passwordConfirmation = $$v
                                          },
                                          expression: "passwordConfirmation"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                VCardActions,
                                [
                                  _c(
                                    VBtn,
                                    {
                                      attrs: {
                                        color: "primary",
                                        "data-cy": "signinSubmitBtn",
                                        loading: _vm.load_troca_senha,
                                        disabled: !_vm.valid
                                      },
                                      on: { click: _vm.submit }
                                    },
                                    [
                                      _vm._v(" Salvar "),
                                      _c(VIcon, { staticClass: "ml-1" }, [
                                        _vm._v("chevron_right")
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.snackbar
                        ? _c("Snackbar", {
                            attrs: {
                              mostrarSnackbar: _vm.snackbar,
                              corSnackbar: _vm.snackbarColor,
                              mensagemSnackbar: _vm.mensagemSnackbar
                            },
                            on: {
                              fecharSnackbar: function($event) {
                                _vm.snackbar = false
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        VLayout,
        { attrs: { "align-center": "", "justify-center": "" } },
        [_c(VFlex, { attrs: { xs12: "", sm8: "", md6: "" } })],
        1
      ),
      _c(
        VDialog,
        {
          attrs: { "max-width": "400" },
          model: {
            value: _vm.dialogMensagemFinal,
            callback: function($$v) {
              _vm.dialogMensagemFinal = $$v
            },
            expression: "dialogMensagemFinal"
          }
        },
        [
          _c(
            VCard,
            [
              _c(VCardText, { staticClass: "pt-5 mb-n10" }, [
                _vm._v(_vm._s(_vm.resposta_requisicao))
              ]),
              _c(
                VCardActions,
                [
                  _c(VSpacer),
                  _c(
                    VBtn,
                    {
                      attrs: { color: "primary", text: "" },
                      on: {
                        click: function($event) {
                          _vm.dialogMensagemFinal = !_vm.dialogMensagemFinal
                        }
                      }
                    },
                    [_vm._v("ok")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }